import React from "react"

import SEO from "../components/SEO"

import ContactPageComp from "../components/ContactPage"
import { UtmContext } from "../utils/utmContext"

const ContactPage = () => {
    const { utm } = React.useContext(UtmContext);
    return (
      <>
        <SEO
          title="Contact brillea"
        />
        <ContactPageComp utm={utm} filter={{form:true, chat:false, signUp:false}}/>
      </>
    )
}

export default ContactPage
